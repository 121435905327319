const state = {
  optionItem: undefined,
  companyId: undefined,
  userInfo: undefined
}

const mutations = {
  OPTION_ITEM: (state, data) => {
    state.optionItem = data
  },
  COMPANY_ID: (state, data) => {
    state.companyId = data
  },
  USER_INFO: (state, data) => {
    state.userInfo = data
  }
}

const actions = {
  changeOptionItem({ commit }, data) {
    commit('OPTION_ITEM', data)
  },
  changeCompanyId({ commit }, data) {
    // console.log(data)
    commit('COMPANY_ID', data)
  },
  getUserInfo({ commit }, data) {
    // console.log(data)
    commit('USER_INFO', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

